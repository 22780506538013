import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getServicePackage}?${queryParams}`,
            withCredentials: true,
        });
    },
    createServicePackage(servicePackageData) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.createServicePackage}`,
            data: servicePackageData,
            withCredentials: true,
        });
    },
    updateServicePackage(servicePackageData) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.updateServicePackage}`,
            data: servicePackageData,
            withCredentials: true,
        });
    },
    deleteServicePackage(packageId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.deleteServicePackage.format(packageId)}`,
            withCredentials: true,
        });
    },
};