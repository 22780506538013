import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import servicePackageApi from '@/api/gpuHub/servicePackage';
import { debounce } from 'vue-debounce';
import { transactionStatusText } from "@/constant/vmMachineStatusConst";
import { colorGpuNodeColor } from "@/constant/vmMachineStatusConst";
import { ValidationProvider } from 'vee-validate';
import { mapState } from "vuex";
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ValidationObserver } from 'vee-validate';
extend('required', required);
export default {
    extends: baseComponent,
    components: {
        ValidationProvider, ValidationObserver
    },
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 5.5rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            colorGpuNodeColor: colorGpuNodeColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,
            elementSelected: null,
            isEditMode: false,
            modifyLoading: false,
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "ordered",
                directionSort: "asc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: this.elements.filterItem
            };
        },
        transactionStatusFilter() {
            return Object.keys(transactionStatusText).map(x => {
                return { id: x, text: transactionStatusText[x] };
            });
        },
        formIsValid() {
            if (this.elementDetail === null) return false;
        }
    },
    created() {
        this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            servicePackageApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        viewDetailInfo(targetItem, asNew = true) {
            this.isEditMode = !asNew;
            if (asNew) {
                if (targetItem === null) targetItem = {};
                targetItem.id = null;
            }
            console.log("this.isEditMode", this.isEditMode);
            console.log("asNew", asNew);
            this.$set(this, "elementSelected", Object.assign({}, targetItem));
            this.$set(this, "elementDetail", Object.assign({}, targetItem));
            this.$nextTick(() => {
                $("#editModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#editModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
        createOrUpdateItem() {
            commonAct.showConfirm(`Save change package info <strong>${this.elementDetail.name}</strong> ?`, () => {
                this.modifyLoading = true;
                console.log("this.isEditMode", this.isEditMode);
                if (!this.isEditMode) {
                    servicePackageApi.createServicePackage(this.elementDetail).then(response => {
                        if (response.data && response.data.result === 0) {
                            this.modifyLoading = false;
                            this.elements.data.push(response.data.data);
                            this.showSuccessToast("Service package information has been saved");
                            this.$nextTick(() => {
                                $("#editModalInfo").modal("hide");
                            });
                        }
                        else {
                            this.modifyLoading = false;
                            if (response.data.message !== null && response.data.message !== '') {
                                commonAct.showError(response.data.message || this.$lang.common.error);
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        this.modifyLoading = false;
                        commonAct.showError(error);

                    });
                }
                else {
                    servicePackageApi.updateServicePackage(this.elementDetail).then(response => {
                        if (response.data && response.data.result === 0) {
                            this.modifyLoading = false;
                            let elementUpdate = this.elements.data.findIndex(x => x.id === this.elementDetail.id);
                            if (elementUpdate > -1) {
                                this.elements.data[elementUpdate] = this.elementDetail;
                            }
                            this.showSuccessToast("Service package information has been saved");
                        }
                        else {
                            this.modifyLoading = false;
                            if (response.data.message !== null && response.data.message !== '') {
                                commonAct.showError(response.data.message || this.$lang.common.error);
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        this.modifyLoading = false;
                        commonAct.showError(error);

                    });
                }
            });
        },
        removeItem(targetItem) {
            commonAct.showConfirm(`Delete package <strong>${targetItem.name}</strong> ?`, () => {
                this.showLoading();
                servicePackageApi.deleteServicePackage(targetItem.id).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.hideLoading();
                        let elementUpdate = this.elements.data.findIndex(x => x.id === targetItem.id);
                        if (elementUpdate > -1) {
                            this.elements.data.splice(elementUpdate, 1);
                            this.showSuccessToast("Service package information has been saved");
                        }
                    }
                    else {
                        this.hideLoading();
                        if (response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.hideLoading();
                    commonAct.showError(error);

                });
            });  
            
        },
    }
}